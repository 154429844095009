import React, {FC} from 'react';
import {Label} from "./index";
import {Input} from "antd";

interface Props {
  title: string
  placeholder?: string
  value?: string
  onChange?: (value: string) => void
}

const StyledInput: FC<Props> = (props) => (
  <Input
    prefix={<Label title={props.title} />}
    {...props}
    onChange={evt => props.onChange && props.onChange(evt.target.value)}
  />
);

export default StyledInput;
