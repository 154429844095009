import React, {FC} from 'react';
import styled from "styled-components";

interface Props {
  title: string
}

const Span = styled.span`
  width: 124px;
  padding-right: 9px;
`

const Label: FC<Props> = ({ title }) => (
  <Span>{title}</Span>
);

export default Label;
