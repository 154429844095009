import React, {FC} from 'react';
import {DefaultTheme, ThemeProvider} from "styled-components";
import { GlobalStyles, theme as defaultTheme } from '../styles';
import { Helmet } from 'react-helmet'

interface State {
  theme?: DefaultTheme
}

const AppThemeProvider: FC<State> = ({ children, theme }) => {

  return (
    <ThemeProvider theme={theme ?? defaultTheme}>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto&display=swap"
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )

}

export default AppThemeProvider;
