export const theme = {
  boxShadow: '0px 3pt 6pt #939597A8',
  transition: '0.3s',

  blue: '#015093',
  cyan: '#B8ECED',
  darkRed: '#bf2025',
  green: '#00BABC',
  magenta: '#ed156f',
  orange: '#f05722',
  pink: '#FFF0F1',
  purple: '#eb131b',
  red: '#eb131b',
  yellow: '#fcbb72',

  mutedBlue: '#9ab9d3',
  mutedCyan: '#d6eeff',
  mutedGreen: '#b3e7e8',
  mutedOrange: '#ffead3',
  mutedPurple: '#f1eaf8',
  mutedRed: '#ffd8d9',

  black: '#222',
  darkCyan: '#11Bfc1',
  darkGray: '#6d6e71',
  darkerGray: '#585858',
  gray: '#939597',
  lightGray: '#c6c8ca',
  lighterGray: '#e6e7e8',
  white: 'white',

  pageBackgroundColor: '#f2f2f2',

  sm: 960,
  xs: 760,
}
