import { createGlobalStyle, css } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => css`
    html {
      height: 100%;
      width: 100%;

      body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        font-family: 'Roboto', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: ${theme.pageBackgroundColor};

        #root {
          
          height: 100%;
          width: 100%;

          h1, h2, h3, h4, h5, h6 {

            font-family: 'Montserrat', sans-serif;
          }

          h1 {
            font-size: 96px;
          }
          h2 {
            font-size: 60px;
          }
          h3 {
            font-size: 48px;
          }
          h4 {
            font-size: 34px;
          }
          h5 {
            font-size: 24px;
          }
          h6 {
            font-size: 20px;
          }

        }

      }
    }
    * {
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
    }
  `}
`
