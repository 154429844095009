import styled, {css} from 'styled-components';


export const Flex = styled.div((props: {
  /* Parent properties */
  fillParent?: boolean
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'start' | 'end' | 'left' | 'right'
  alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'first baseline' | 'last baseline' | 'start' | 'end' | 'self-start' | 'self-end'
  alignContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch' | 'start' | 'end' | 'baseline' | 'first baseline' | 'last baseline'
  /* Children properties */
  order?: number
  flexGrow?: number
  flexShrink?: number
  flexBasis?: number | string | 'auto' | 'content' | 'max-content' | 'min-content' | 'fit-content'
  alignSelf?: 'auto' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
}) => css`
  display: flex;
  width: ${props.fillParent ? '100%' : 'auto'};
  height: ${props.fillParent ? '100%' : 'auto'};
  flex-direction: ${props.flexDirection ?? 'row'};
  flex-wrap: ${props.flexWrap ?? 'nowrap'};
  justify-content: ${props.justifyContent ?? 'flex-start'};
  align-items: ${props.alignItems ?? 'normal'};
  align-content: ${props.alignContent ?? 'normal'};
  order: ${props.order ?? 0};
  flex-grow: ${props.flexGrow ?? 0};
  flex-shrink: ${props.flexShrink ?? 1};
  flex-basis: ${props.flexBasis ?? 'auto'};
  align-self: ${props.alignSelf ?? 'auto'};
`)
