import React, {useState} from 'react'
import {AppThemeProvider} from "./providers"
import {Alert, Button, Col, Divider, Form, Image, notification, Row, Space} from "antd"
import StyledInput from "./components/StyledInput"
import md5 from "blueimp-md5"
import moment from "moment"

const ENDPOINT = '/Pages/Menu/memberstransfer.aspx'

function App() {

  const [ssoLink, setSSOLink] = useState<string>()

  const log = (...data: any) => process.env.NODE_ENV === 'development' && console.log(...data)

  const getMd5 = (plain: string, secret: string) => md5(plain + secret).toUpperCase()
  const generateHandshake = (faculty: string, student: string, secret: string) => {
    const currentDate = moment().format('MMM-DD-YYYY')

    log('Current date: ', currentDate)

    return getMd5(`AllowAccessMoshal${currentDate}${student}${faculty}`, secret)
  }

  const handleFinish = (
    params: {
      domain?: string,
      secret?: string,
      student?: string,
      faculty?: string
    }
  ) => {

    if (!(
      params.domain &&
      params.secret &&
      params.faculty &&
      params.student
    )) return

    const handshake = generateHandshake(
      params.faculty,
      params.student,
      params.secret
    )

    log('Handshake:', handshake)

    const q = new URLSearchParams()
    q.append('x_unum', params.student)
    q.append('x_fac', params.faculty)
    q.append('x_handshake', handshake)

    const qParams = q.toString()

    const link = `${params.domain}${ENDPOINT}?${qParams}`
    setSSOLink(link)

    notification['success']({
      message: 'Success',
      description:
        'New link has been generated successfully.',
    });

  }

  return (
    <AppThemeProvider>
      <Form onFinish={handleFinish}>
        <Row justify="center">
          <Col span={10}>
            <Row style={{ paddingTop: '18px' }}>
              <Image width={256} src="./assets/logo.png" />
            </Row>
            <Row>
              <h1>Test SSO</h1>
            </Row>
            <Space direction="vertical">
              <Row>
                <Col span={24}>
                  <Form.Item name="domain">
                    <StyledInput
                      title="Client Domain"
                      placeholder="Type in valid domain to PERLS. e.g. http://live.perlsreading.com"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="secret">
                    <StyledInput title="Client Secret" placeholder="Type in client secret"/>
                  </Form.Item>
                </Col>
                <Col>
                  <Divider/>
                </Col>
                <Col span={24}>
                  <Form.Item name="student">
                    <StyledInput title="Student No" placeholder="Type in student number"/>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="faculty">
                    <StyledInput title="Faculty" placeholder="Type in faculty"/>
                  </Form.Item>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">Generate Link</Button>
                </Col>
              </Row>

              <Row>
                {ssoLink && (
                  <Alert message={<a rel="noreferrer" target="_blank" href={ssoLink}>{ssoLink}</a>} type="success"/>
                )}
                {!ssoLink && (<Alert message="No link has been generated yet." type="info"/>)}
              </Row>

            </Space>

          </Col>
        </Row>
      </Form>

    </AppThemeProvider>
  );
}

export default App;
